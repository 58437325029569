import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import allImage from '../assets/all.png'; // Assurez-vous que le chemin vers votre image est correct
import loveIcon from '../assets/love.svg';
import bioIcon from '../assets/bio.svg';
import smileIcon from '../assets/smile.svg';
import ProductList from '../components/ProductList';
import BecomeSeller from '../components/BecomeSeller';

function Home() {
  return (
    <Container sx={{ px: { xs: 0, md: 0 } }}>
      <Grid container spacing={8} alignItems="center" sx={{ pt: 0, px: 4, minHeight: '90vh' }} >
        <Grid item xs={12} md={6}>
          <img src={allImage} alt="All" style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', mb: 1 }}>
            les gourmandises qui relaxent toutes les tribus
          </Typography>
          <Box display="flex" justifyContent="flex-start" sx={{ mt: 2 }}>
            <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ mr: 4 }}>
              <img src={loveIcon} alt="Love" style={{ height: 40, marginBottom: 8 }} />
              <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'left', textTransform: 'uppercase', lineHeight: '1.3em' }}>
                Finance une<br />association
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ mr: 4 }}>
              <img src={bioIcon} alt="Bio" style={{ height: 40, marginBottom: 8 }} />
              <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'left', textTransform: 'uppercase', lineHeight: '1.3em' }}>
                Bio, vegan<br />& équitable
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <img src={smileIcon} alt="Smile" style={{ height: 40, marginBottom: 8 }} />
              <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'left', textTransform: 'uppercase', lineHeight: '1.3em' }}>
                Fabriqué<br />en France
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box id="products" sx={{ mt: 4 }}>
        <ProductList />
      </Box>
      <Box sx={{ mt: 4 }}>
        <BecomeSeller />
      </Box>
    </Container>
  );
}

export default Home;
