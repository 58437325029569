import chocolatChaud from '../assets/chocolat-chaud.png';
import chocolatNoir from '../assets/tablette-noir.png';
import chocolatOnctueux from '../assets/tablette-onctueux.png';
import pateRecharger from '../assets/recharge.png';
import potRecharger from '../assets/pot-doseur.png';
import potClassique from '../assets/pot-classique.png';

const productData = [
  {
    id: 1,
    image: chocolatChaud,
    title: 'Chocolat Chaud',
    ingredients: 'Sucre complet*, cacao*.\n* Ingrédients issus de l’agriculture biologique, agriculture Non UE.',
    nutrition: 'Energie 1677 kJ 397 kcal ; Matières grasses 7g, dont acides gras saturés 4,6g ; Glucides 62g, dont sucres 57g ; Fibres alimentaires 14,0g ; Protéines 7,7g ; Sel 0.11g'
  },
  {
    id: 2,
    image: chocolatNoir,
    title: 'Chocolat Noir',
    ingredients: 'Pâte de cacao*, sucre de canne*, beurre de cacao*.\n* Ingrédients issus de l’agriculture biologique.',
    nutrition: 'Energie 2340 kJ 565 kcal ; Matières grasses 42g, dont acides gras saturés 26,6g ; Glucides 30g, dont sucres 26g ; Fibres alimentaires 16,6g ; Protéines 8,1g ; Sel 0.1g'
  },
  {
    id: 3,
    image: chocolatOnctueux,
    title: 'Chocolat Onctueux',
    ingredients: 'Sucre de canne*, beurre de cacao*, poudre de riz*, pâte de cacao* (11%), noisettes broyées*, vanille en poudre*.\n* Ingrédients issus de l’agriculture biologique.',
    nutrition: 'Energie 2418 kJ 581 kcal ; Matières grasses 39g, dont acides gras saturés 22,7g ; Glucides 55g, dont sucres 43g ; Fibres alimentaires 3,2g ; Protéines 2,1g ; Sel 0.1g'
  },
  {
    id: 4,
    image: pateRecharger,
    title: 'Pâte à Recharger',
    ingredients: 'Sucre complet*, noisettes (32%)*, avoine*, cacao*.\n* Ingrédients issus de l’agriculture biologique.',
    nutrition: 'Energie 1439 kJ 342 kcal ; Matières grasses 15g, dont acides gras saturés 1,8g ; Glucides 40g, dont sucres 31g ; Fibres alimentaires 3,5g ; Protéines 4,9g ; Sel 0.03g'
  },
  {
    id: 5,
    image: potRecharger,
    title: 'Pot à Recharger',
    ingredients: 'Sucre complet*, noisettes (32%)*, avoine*, cacao*.\n* Ingrédients issus de l’agriculture biologique.',
    nutrition: 'Energie 1439 kJ 342 kcal ; Matières grasses 15g, dont acides gras saturés 1,8g ; Glucides 40g, dont sucres 31g ; Fibres alimentaires 3,5g ; Protéines 4,9g ; Sel 0.03g.'
  },
  {
    id: 6,
    image: potClassique,
    title: 'Pâte à tartiner',
    ingredients: 'Sucre complet*, noisettes (32%)*, eau, avoine*, cacao*.\n* Ingrédients issus de l’agriculture biologique.',
    nutrition: 'Energie 1385 kJ 331 kcal ; Matières grasses 16g, dont acides gras saturés 1,8g ; Glucides 36g, dont sucres 34g ; Fibres alimentaires 4,0g ; Protéines 4,7g ; Sel 0.02g'
  },
];

export default productData;
