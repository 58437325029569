import { createTheme } from '@mui/material/styles';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Aniara';
    src: url('/fonts/Aniara-Bold.woff2') format('woff2'),
         url('/fonts/Aniara-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Colby Narrow';
    src: url('/fonts/Colby-NrBld.woff2') format('woff2'),
         url('/fonts/Colby-NrBld.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  body {
    margin: 0;
    padding: 0;
    min-height:100vh;
    background: linear-gradient(to bottom, #BEE0F3, #E5F3FA);
    box-sizing: border-box;
    font-family: 'Colby Narrow', Arial, sans-serif;
    color: black; /* Texte noir par défaut */
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Aniara', Arial, sans-serif;
    text-transform: uppercase; /* Titres en uppercase */
  }
`;

const theme = createTheme({
  typography: {
    fontFamily: 'Colby Narrow, Arial, sans-serif', // Colby Narrow as default font
    h1: {
      fontFamily: 'Aniara, Arial, sans-serif', // Aniara for headings
      textTransform: 'uppercase',
      letterSpacing: '-0.03em',
      lineHeight: '1em',
    },
    h2: {
      fontFamily: 'Aniara, Arial, sans-serif',
      textTransform: 'uppercase',
      letterSpacing: '-0.03em',
      lineHeight: '1em',
    },
    h3: {
      fontFamily: 'Aniara, Arial, sans-serif',
      textTransform: 'uppercase',
      letterSpacing: '-0.03em',
      lineHeight: '1em',
    },
    h4: {
      fontFamily: 'Aniara, Arial, sans-serif',
      textTransform: 'uppercase',
      letterSpacing: '-0.03em',
      lineHeight: '1em',
    },
    h5: {
      fontFamily: 'Aniara, Arial, sans-serif',
      textTransform: 'uppercase',
      letterSpacing: '-0.03em',
      lineHeight: '1em',
    },
    h6: {
      fontFamily: 'Aniara, Arial, sans-serif',
      textTransform: 'uppercase',
      letterSpacing: '-0.03em',
      lineHeight: '1em',
    },
    body1: {
      color: 'black', // Texte noir
    },
    body2: {
      color: 'black', // Texte noir
    },
  },
});

export default theme;
