import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; // Ajouter une icône d'information
import productData from '../data/productData';
import ProductModal from './ProductModal';

const ProductList = () => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleOpen = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  return (
    <>
      <Grid sx={{ px:4 }} container spacing={4}>
        {productData.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <Card onClick={() => handleOpen(product)} sx={{ cursor: 'pointer', boxShadow: 'none', height: '100%' }}>
              <CardMedia
                component="img"
                image={product.image}
                alt={product.title}
                sx={{ height: 300, width: '100%', objectFit: 'contain', backgroundColor:'#ffcfcf' }} // Faire en sorte que les images soient carrées
              />
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" component="div" sx={{ textAlign: 'center', mt:1, mb:0, color:'black' }}>
                  {product.title}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', mt: 1 }}>
                  <InfoIcon fontSize="small" sx={{ mr: 1 }} />
                  En savoir plus
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ProductModal open={open} onClose={handleClose} product={selectedProduct} />
    </>
  );
};

export default ProductList;
