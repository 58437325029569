import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const BecomeSeller = () => {
  return (
    <Container id="become-seller" sx={{ py: 12, textAlign: 'center' }}>
      <Typography variant="h3" component="h2" sx={{ mb: 0.5 }}>
        Devenir Revendeur
      </Typography>
      <Box sx={{ mb: 2 }}>
      <Typography variant="body">
        Rejoignez-nous et proposez nos produits dans vos magasins !
      </Typography>
      </Box>
        <Button startIcon={<MailOutlineIcon />} variant="contained" href="mailto:hey@sauvezlaforet.fr" style={{ backgroundColor: 'black', color: '#fff', boxShadow: 'none', textTransform: 'uppercase' }}>
        Contactez-nous
        </Button>
    </Container>
  );
};

export default BecomeSeller;
