import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.svg'; // Assurez-vous que le chemin vers votre logo est correct

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', px: 4 }}>
      <List>
        <ListItem button href="#products">
          <ListItemText primary="Produits" sx={{ textTransform: 'uppercase' }} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '20px 0' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Box display={{ xs: 'flex', md: 'none' }} alignItems="center">
            <Link to="/">
              <img src={logo} alt="Logo" style={{ height: 50 }} /> {/* Logo plus petit et aligné à gauche */}
            </Link>
          </Box>
          <Box display={{ xs: 'none', md: 'flex' }} alignItems="center" flexGrow={1}>
            <Button href="#products" style={{ color: 'black', textTransform: 'uppercase' }}>Produits</Button>
          </Box>
          <Box display={{ xs: 'flex', md: 'none' }} alignItems="center">
            <Button variant="contained" href="mailto:namaste@sauvezlaforet.fr" style={{ backgroundColor: 'black', color: 'white', boxShadow: 'none', textTransform: 'uppercase' }}>
              Devenir Vendeur
            </Button>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '50%',
                ml: 2,
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box display={{ xs: 'none', md: 'flex' }} justifyContent="center" alignItems="center" flexGrow={1}>
            <Link to="/">
              <img src={logo} alt="Logo" style={{ height: 80 }} /> {/* Logo centré et plus grand */}
            </Link>
          </Box>
          <Box display={{ xs: 'none', md: 'flex' }} justifyContent="flex-end" alignItems="center" flexGrow={1}>
            <Button variant="contained" href="mailto:hey@sauvezlaforet.fr" style={{ backgroundColor: 'black', color: 'white', boxShadow: 'none', textTransform: 'uppercase' }}>
              Devenir Vendeur
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}

export default Header;
