import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import logoBio from '../assets/logo-bio.svg'; // Assurez-vous que le chemin vers votre image est correct

const ProductModal = ({ open, onClose, product }) => {
  if (!product) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: 300, 
        borderRadius:'8px',
        bgcolor: 'background.paper', 
        border: '0px !important', 
        boxShadow: 24, 
        p: 4 
      }}>
        <Typography variant="h4" component="h2">{product.title}</Typography>
        <Typography sx={{ mt: 2 }}>INGRÉDIENTS :<br />{product.ingredients}</Typography>
        <Typography sx={{ mt: 2 }}>INFORMATIONS NUTRITIONNELLES :<br />{product.nutrition}</Typography>
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <img src={logoBio} alt="Logo Bio" style={{ height: 70 }} />
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductModal;
