import React from 'react';
import { Box, Button, Typography, Link as MuiLink } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import footerBackground from '../assets/footer.png'; // Assurez-vous que le chemin vers votre image est correct
import logo from '../assets/logo.svg'; // Assurez-vous que le chemin vers votre logo est correct

function Footer() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${footerBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        px: 4,
        color: 'black',
        pt: '5vh',
        pb: '5vh',
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" sx={{ pt: '10vh' }}>
        <img src={logo} alt="Logo" style={{ height: 70, marginBottom: '10px' }} /> {/* Logo centré */}
        <Typography sx={{ mb:2 }}>
            SENSEI FAMILY<br />
            122 rue Amelot 75011 
        </Typography>
        <Button variant="contained" href="mailto:namaste@sauvezlaforet.fr" style={{ backgroundColor: 'black', color: '#156e38', boxShadow: 'none', textTransform: 'uppercase' }}>
              Devenir Vendeur
        </Button>
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
          <MuiLink href="https://instagram.com" target="_blank" color="inherit" sx={{ mx: 1 }}>
            <InstagramIcon />
          </MuiLink>
          <MuiLink href="https://youtube.com" target="_blank" color="inherit" sx={{ mx: 1 }}>
            <YouTubeIcon />
          </MuiLink>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
